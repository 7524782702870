import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';

// Components
import MapleIconButton from 'Components/ds/MapleIconButton';
import MapleIcon from 'Components/ds/MapleIcon';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

type Size = 'small' | 'medium' | 'large';

export interface MapleDialogProps extends DialogProps {
  handleClose?: () => void;
  children: ReactNode;
  size?: Size;
  variant?: 'normal' | 'header-fill';
  withCloseIcon?: boolean;
}

const sizeMap: Record<Size, string> = {
  small: '448px',
  medium: '600px',
  large: '800px',
};

const StyledDialog = styled(Dialog)<MapleDialogProps & { size?: Size }>(
  ({ theme, size = 'small', variant = 'normal' }) => ({
    backgroundColor: 'none',
    padding: 0,

    '& .MuiDialog-paper': {
      borderRadius: theme.shape.lg,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: sizeMap[size],
      maxWidth: `calc(100vw - ${theme.spacing(4)})`,
    },

    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(12.5px)',
    },

    '.CloseButton': {
      position: 'absolute',

      ...(variant === 'header-fill'
        ? {
            top: theme.spacing(4.5),
            right: theme.spacing(4.5),
          }
        : {
            top: theme.spacing(3),
            right: theme.spacing(3),
          }),

      [theme.breakpoints.down('md')]: {
        top: theme.spacing(1),
        right: theme.spacing(1),
      },
    },
  }),
);

const MapleDialog = ({ handleClose, withCloseIcon, children, ...props }: MapleDialogProps) => {
  const { isDesktopDevice } = useBreakpoint();

  const handleOnClose = (_, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (withCloseIcon && reason === 'backdropClick') return;

    if (handleClose) {
      handleClose();
    }
  };

  return (
    <StyledDialog onClose={handleOnClose} {...props}>
      {withCloseIcon && (
        <MapleIconButton
          size={isDesktopDevice ? 'small' : 'xSmall'}
          color='tertiary'
          onClick={handleClose}
          className='CloseButton'
        >
          <MapleIcon icon='closeLine' />
        </MapleIconButton>
      )}

      {children}
    </StyledDialog>
  );
};

export default MapleDialog;
