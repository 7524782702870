import { Addresses } from 'Contracts/addresses';

const addresses: Addresses = {
  mpl: '0x46e33a8376a64d5293a5a4e785cbbee1eb205520',
  xMPL: '0x23b2c26f1021ec01e41f4b08c790821167056110',
  syrupToken: '0xfdd51bd2dc09933b5f8c9ea2533c02849a64c484',
  stSyrup: '0xc5f044f026a43cf1144a173e2f0567443f3a70f7',
  syrupTokenMigrator: '0x4325d437b189122a93a0b99b37effa22dcb81072',
  mplUserActions: '0xb962c882f103f76424b7dc2b92575c430ba593d5',
  pendleLP: '0x0000000000000000000000000000000000000000',
  pendlePT: '0x0000000000000000000000000000000000000000',
  pendleYT: '0x0000000000000000000000000000000000000000',
};

export default addresses;
