// Components
import PageContainer from 'Components/PageContainer';

// Context
import { ClientProvider } from 'Context/Client';
import WalletConnect from 'Context/WalletConnect';
import { AnalyticsProvider } from 'Context/SegmentAnalytics';
import { DataProvider } from 'Context/Data';
import { RestrictedProvider } from 'Context/Restricted';
import { AppModalProvider } from 'Context/AppModal';
import { ReferralProvider } from 'Context/Referral';

// Theme
import ThemeConfig from 'Theme';

// Apollo
import { ApolloProvider } from '@apollo/client';
import { ApolloClient } from 'Services/ApolloClient';

// MUI licensing library
import { LicenseInfo } from '@mui/x-license-pro';

// Set MUI license key
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE}`);

function App() {
  return (
    <ApolloProvider client={ApolloClient}>
      <AnalyticsProvider writeKey={process.env.REACT_APP_SEGMENT_KEY ?? ''}>
        <ThemeConfig>
          <WalletConnect>
            <ClientProvider>
              <RestrictedProvider>
                <DataProvider>
                  <ReferralProvider>
                    <AppModalProvider>
                      <PageContainer />
                    </AppModalProvider>
                  </ReferralProvider>
                </DataProvider>
              </RestrictedProvider>
            </ClientProvider>
          </WalletConnect>
        </ThemeConfig>
      </AnalyticsProvider>
    </ApolloProvider>
  );
}

export default App;
