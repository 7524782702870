import { parseUnits } from 'viem/utils';

import { PERCENTAGE_DECIMALS } from 'Constants/tokens';
import { defaultValueInterface } from 'Utils/defaultValues';
import { parseUserInputWithDecimals } from 'Utils/form';
import { formatNumber, getFormattedNumber } from 'Utils/numbers';
import { ZERO } from 'Constants/numbers';

export interface ValueInterface {
  bigNumber: bigint;
  formatted: string;
  parsed: string;
}

export const buildStringValueInterface = (value: string): ValueInterface => {
  return {
    formatted: value,
    parsed: value,
    bigNumber: defaultValueInterface.bigNumber,
  };
};

export const buildValueInterface = (value: string, decimals: number): ValueInterface => {
  let formatted = '';
  let bigNumber = ZERO;

  if (value !== '') {
    formatted = formatNumber(value, decimals);
    bigNumber = parseUserInputWithDecimals(value, decimals);
  }

  return {
    formatted,
    bigNumber,
    parsed: bigNumber.toString(),
  };
};

export const buildValueInterfaceFromBigNumberValues = (
  bigNumber: bigint,
  decimals: number,
  decimalsToShow = 0,
): ValueInterface => {
  let formatted = '';
  let parsed = '';

  if (bigNumber || bigNumber === ZERO) {
    formatted = getFormattedNumber({ bigNumber, decimals, decimalsToShow });
    parsed = bigNumber.toString();
  }

  return {
    formatted,
    bigNumber,
    parsed,
  };
};

export const buildValueInterfaceFromParsedValues = (
  parsed: string,
  decimals: number,
  decimalsToShow = 0,
): ValueInterface => {
  let formatted = '';
  let bigNumber = ZERO;

  if (parsed !== '') {
    bigNumber = BigInt(parsed);
    formatted = getFormattedNumber({ bigNumber, decimals, decimalsToShow });
  }

  return {
    formatted,
    bigNumber,
    parsed,
  };
};

export const buildPercentageValueInterfaceFromBigNumber = (
  bigNumber: bigint,
  decimals: number,
  decimalsToShow = 0,
): ValueInterface => {
  const formatted = getFormattedNumber({ bigNumber: bigNumber * BigInt(100), decimals, decimalsToShow });
  const parsed = bigNumber.toString();

  return {
    formatted,
    bigNumber,
    parsed,
  };
};

export const buildPercentageValueInterfaceFromString = (
  value: string,
  decimals?: number,
  decimalsToShow?: number,
): ValueInterface => {
  const percentageValue = +value > 100 ? '100' : formatNumber(value, decimalsToShow ?? PERCENTAGE_DECIMALS);

  const bigNumber = decimals
    ? parseUnits(percentageValue, decimals) / BigInt(100)
    : BigInt(percentageValue) / BigInt(100);

  return {
    formatted: percentageValue,
    bigNumber,
    parsed: bigNumber.toString(),
  };
};

// ValueInterface constants
export const ZERO_USDC = {
  formatted: '0',
  bigNumber: ZERO,
  parsed: '0',
};

export const ZERO_DASH_USDC = {
  formatted: '-',
  bigNumber: ZERO,
  parsed: '0',
};

export const EMPTY_PERCENTAGE = {
  formatted: '0',
  bigNumber: ZERO,
  parsed: '0',
};

export const valueInterfaceComparator = (v1: ValueInterface, v2: ValueInterface) => Number(v1.bigNumber - v2.bigNumber);
