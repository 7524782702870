import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { isConvertEnabled as defaultIsConvertEnabled } from 'Constants/flags';

const useFeatureFlags = () => {
  const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);

  // Extract convert parameter
  const convertParam = queryParams.get('convert');

  // Track if isConvertEnabled was ever true
  const hasBeenEnabled = useRef(false);

  // Initialize the state, but only set to true once
  const [isConvertEnabled, setIsConvertEnabled] = useState(() => {
    const enabled = convertParam !== null || defaultIsConvertEnabled;
    if (enabled) {
      hasBeenEnabled.current = true;
    }
    return enabled;
  });

  useEffect(() => {
    // If isConvertEnabled is already true, don't change it
    if (!isConvertEnabled && convertParam !== null) {
      setIsConvertEnabled(true);
      hasBeenEnabled.current = true;
    }
  }, [convertParam, isConvertEnabled]);

  useEffect(() => {
    // Ensure that once isConvertEnabled is true, it never goes back to false
    if (hasBeenEnabled.current) {
      setIsConvertEnabled(true);
    }
  }, [isConvertEnabled]);

  return {
    isConvertEnabled,
  };
};

export default useFeatureFlags;
