import { Address } from 'viem';

import { PROJECT } from 'Constants/network';
import localhostAddresses from 'Contracts/addresses/localhost';
import mainnetProdAddresses from 'Contracts/addresses/mainnet-prod';
import sepoliaDevAddresses from 'Contracts/addresses/sepolia-dev';


type MapleContracts = 'syrupTokenMigrator' | 'mpl' | 'xMPL' | 'syrupToken' | 'stSyrup' | 'mplUserActions';
type PendleContracts = 'pendleLP' | 'pendlePT' | 'pendleYT';
type Contracts = MapleContracts | PendleContracts;

export type Addresses = Record<Contracts, Address>;

const ADDRESSES_MAP: Record<string, Addresses> = {
  localhost: localhostAddresses,
  'mainnet-prod': mainnetProdAddresses,
  'sepolia-dev': sepoliaDevAddresses,
};

export const ADDRESSES = ADDRESSES_MAP[PROJECT];
