import { ReactNode, createContext, useMemo, useContext } from 'react';

// Context
import { ClientContext } from 'Context/Client';

// Hooks
import useIsMarketingPage from 'Hooks/useIsMarketingPage';
import useConvertPageLocation from 'Hooks/useConvertPageLocation';

// GraphQL
import { useIsRestrictedQuery, useGetAddressComplianceQuery } from 'Graphql/schema';

export interface RestrictedContextType {
  isRestricted: boolean;
}
export const RestrictedContext = createContext<RestrictedContextType>({
  isRestricted: false,
});

type Props = {
  children: ReactNode;
};

export const RestrictedProvider = ({ children }: Props) => {
  const isMarketingPage = useIsMarketingPage();
  const isConvertPage = useConvertPageLocation();
  const { account } = useContext(ClientContext);

  const { error: isRestrictedError } = useIsRestrictedQuery({
    skip: isMarketingPage || isConvertPage,
  });

  const { data: addressComplianceData } = useGetAddressComplianceQuery({
    variables: { address: account?.toLowerCase() || '' },
    skip: !account,
  });

  const isGeoBlocked = useMemo(() => {
    if (isRestrictedError?.graphQLErrors[0]?.extensions.code === 'RESTRICTED') return true;
    return false;
  }, [isRestrictedError]);

  const isFlaggedAddress = useMemo(() => {
    if (addressComplianceData?.addressCompliance) return !addressComplianceData.addressCompliance.permitted;
    return false;
  }, [addressComplianceData]);

  return (
    <RestrictedContext.Provider value={{ isRestricted: isGeoBlocked || isFlaggedAddress }}>
      {children}
    </RestrictedContext.Provider>
  );
};
