import { Chain, connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  injectedWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  phantomWallet,
  safeWallet,
  walletConnectWallet,
  binanceWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { createConfig, http } from '@wagmi/core';
import { mainnet, sepolia, localhost } from 'wagmi/chains';

import { exodusWallet } from 'Constants/exodusWallet';
import { CollateralAsset } from 'Graphql/schema';

coinbaseWallet.preference = 'eoaOnly';

export type Network = 'mainnet' | 'sepolia' | 'localhost';
export type Project = 'sepolia-dev' | 'sepolia-prod' | 'mainnet-dev' | 'mainnet-prod' | 'localhost';

const LOCALHOST_RPC_URL = process.env.REACT_APP_LOCALHOST_RPC_URL || 'http://localhost:8545';
const SEPOLIA_RPC_URL = process.env.REACT_APP_SEPOLIA_RPC_URL || '';
const MAINNET_RPC_URL = process.env.REACT_APP_MAINNET_RPC_URL || '';

const CHAINS: Record<Network, Chain> = {
  mainnet: {
    ...mainnet,
    rpcUrls: {
      default: { http: [MAINNET_RPC_URL] },
    },
  },
  sepolia: {
    ...sepolia,
    rpcUrls: {
      default: { http: [SEPOLIA_RPC_URL] },
    },
  },
  localhost: {
    ...localhost,
    id: 31337,
    rpcUrls: {
      default: { http: [LOCALHOST_RPC_URL] },
    },
  },
};

export const NETWORK = (process.env.REACT_APP_NETWORK as Network) || 'localhost';
export const PROJECT = (process.env.REACT_APP_PROJECT as Project) || 'localhost';
export const APP_NAME = process.env.REACT_APP_NAME || 'Syrup Finance';
export const WALLET_CONNECT_PROJECT_ID =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || 'fbbc4948d74431d7a76fd73f0951ec80';
export const CHAIN = CHAINS[NETWORK];

const wallets = [
  coinbaseWallet,
  metaMaskWallet,
  safeWallet,
  walletConnectWallet,
  ledgerWallet,
  exodusWallet,
  phantomWallet,
  injectedWallet,
  okxWallet,
  binanceWallet,
];

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets,
    },
  ],
  {
    appName: APP_NAME,
    projectId: WALLET_CONNECT_PROJECT_ID,
  },
);

export const config = createConfig({
  chains: [CHAIN],
  connectors,
  transports: {
    [CHAIN.id]: http(CHAIN.rpcUrls.default.http[0]),
  },
});

const etherscanUrls: Record<Network, string> = {
  sepolia: 'https://sepolia.etherscan.io',
  mainnet: 'https://etherscan.io',
  localhost: 'https://etherscan.io',
};

export const etherscanUrl = etherscanUrls[NETWORK] || etherscanUrls.mainnet;

type OnchainAsset = Extract<
  CollateralAsset,
  | 'ADA'
  | 'AKT'
  | 'AVAX'
  | 'aUSDC'
  | 'aUSDT'
  | 'BNB'
  | 'BTC'
  | 'ETH'
  | 'FIL'
  | 'FTM'
  | 'LBTC'
  | 'SOL'
  | 'USDC'
  | 'WSTETH'
  | 'XRP'
  | 'PT_sUSDE'
  | 'OrcaLP_PYUSDC'
  | 'USTB'
  | 'sDAI'
  | 'sUSDS'
  | 'tETH'
>;

const onchainUrls: { [K in OnchainAsset]: string } = {
  ADA: 'https://cardanoscan.io/address',
  AKT: 'https://www.mintscan.io/akash/assets/native',
  AVAX: 'https://avascan.info/blockchain/c/address',
  aUSDC: 'https://etherscan.io/address',
  aUSDT: 'https://etherscan.io/address',
  BNB: 'https://bscscan.com/address',
  BTC: 'https://blockchain.info/address',
  ETH: 'https://etherscan.io/address',
  FIL: 'https://filfox.info/en/address',
  FTM: 'https://ftmscan.com/address',
  LBTC: 'https://etherscan.io/address',
  SOL: 'https://solscan.io/account',
  USDC: 'https://etherscan.io/address',
  WSTETH: 'https://etherscan.io/address',
  XRP: 'https://xrpscan.com/account',
  OrcaLP_PYUSDC: 'https://solscan.io/account',
  PT_sUSDE: 'https://etherscan.io/address',
  tETH: 'https://etherscan.io/address',
  USTB: 'https://etherscan.io/address',
  sDAI: 'https://etherscan.io/address',
  sUSDS: 'https://etherscan.io/address',
};

export const getOnchainAddressLink = (asset: CollateralAsset, address: string): string | null => {
  return onchainUrls[asset] ? `${onchainUrls[asset]}/${address}` : null;
};
