import { forwardRef } from 'react';
import { styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { hexToRgba } from 'Utils/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xSmall: true;
  }

  interface ButtonPropsVariantOverrides {
    outlined: false;
    textLink: true;
  }

  interface ButtonPropsColorOverrides {
    default: false;
    error: false;
    info: false;
    success: false;
    warning: false;
    tertiary: true;
  }
}

export interface MapleButtonProps extends ButtonProps {
  opacity?: number;
  fontSize?: 'small' | 'medium' | 'large';
}

export interface ColorStyles {
  backgroundColor: string;
  color: string;
  boxShadow: string;
  border?: string;
  '&:hover': {
    backgroundColor: string;
    boxShadow: string;
  };
  '&:active': {
    backgroundColor: string;
    boxShadow: string;
    borderColor?: string;
  };
  '&:disabled': {
    backgroundColor: string;
    color: string;
    boxShadow: string;
    borderColor?: string;
  };
}

const setBackgroundOpacity = (colorStyles: ColorStyles, opacity: number) => {
  return {
    ...colorStyles,
    backgroundColor: hexToRgba(colorStyles.backgroundColor, opacity),
    '&:hover': {
      ...colorStyles['&:hover'],
      backgroundColor: hexToRgba(colorStyles['&:hover'].backgroundColor, opacity),
    },
    '&:active': {
      ...colorStyles['&:active'],
      backgroundColor: hexToRgba(colorStyles['&:active'].backgroundColor, opacity),
    },
    '&:disabled': {
      ...colorStyles['&:disabled'],
      backgroundColor: hexToRgba(colorStyles['&:disabled'].backgroundColor, opacity),
    },
  };
};

const StyledButton = styled(Button)<MapleButtonProps>(
  ({ theme, size = 'large', color = 'primary', variant = 'contained', opacity, fontSize }) => {
    const sizeStyles = {
      xSmall: {
        ...theme.typography.paragraph,
        ...theme.typography.paragraph.small,
        padding: theme.spacing(0.75, 1.5),
        svg: {
          width: '16px',
          height: '16px',
        },
        '& .MuiButton-endIcon': {
          marginLeft: '6px !important',
        },
      },
      small: {
        ...theme.typography.paragraph,
        ...theme.typography.paragraph.small,
        padding: theme.spacing(1.002, 1.75),
        svg: {
          width: '16px',
          height: '16px',
        },
      },
      medium: {
        ...theme.typography.paragraph,
        ...theme.typography.paragraph.medium,
        padding: theme.spacing(1.5, 1.75),
        svg: {
          width: '18px',
          height: '18px',
        },
        '& .MuiButton-startIcon': {
          marginRight: '4px',
          marginLeft: '0px',
        },
      },
      large: {
        ...theme.typography.paragraph,
        ...theme.typography.paragraph.medium,
        padding: theme.spacing(2),
        svg: {
          width: '20px',
          height: '20px',
        },
      },
    };

    const colorStyles: Record<'primary' | 'secondary' | 'tertiary', ColorStyles> = {
      primary: {
        backgroundColor: theme.palette.background.strong,
        color: theme.palette.text.white,
        boxShadow: theme.syrupShadows.md,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.weak || '',
          color: theme.palette.text.disabled,
          boxShadow: 'none',
        },
      },
      secondary: {
        backgroundColor: theme.palette.background.white,
        color: theme.palette.text.strong,
        boxShadow: theme.syrupShadows.lg,
        border: `0.5px solid ${theme.palette.stroke.soft}`,
        '&:hover': {
          backgroundColor: theme.palette.background.white,
          boxShadow: theme.syrupShadows.sm,
        },
        '&:active': {
          backgroundColor: theme.palette.background.weak || '',
          borderColor: theme.palette.background.weak || '',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.weak || '',
          borderColor: theme.palette.background.weak || '',
          color: theme.palette.text.disabled,
          boxShadow: 'none',
        },
      },
      tertiary: {
        backgroundColor: theme.palette.background.weak || '',
        color: theme.palette.text.strong,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.background.soft || '',
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.background.sub || '',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: theme.palette.background.weak || '',
          color: theme.palette.text.disabled,
          boxShadow: 'none',
        },
      },
    };

    const textVariant = {
      backgroundColor: 'transparent',
      color: theme.palette.text.strong,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.text.strong,
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: theme.palette.text.strong,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.text.strong,
      },
    };

    const textLinkVariant = {
      backgroundColor: 'transparent',
      color: theme.palette.text.soft,
      boxShadow: 'none',
      padding: 0,
      minWidth: 'auto',
      '& .MuiButton-endIcon': {
        marginLeft: theme.spacing(0.5),
        marginRight: 0,
        '& svg': {
          fill: 'currentColor',
        },
      },
      '&:hover, &:active, &:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.text.strong,
      },
    };

    const fontSizeOverride = {
      ...(fontSize === 'small' && {
        ...theme.typography.label,
        ...theme.typography.label.small,
      }),
      ...(fontSize === 'medium' && {
        ...theme.typography.label,
        ...theme.typography.label.medium,
      }),
      ...(fontSize === 'large' && {
        ...theme.typography.label,
        ...theme.typography.label.large,
      }),
    };

    return {
      textTransform: 'none',
      fontStyle: 'normal',
      borderWidth: 0,
      borderRadius: '200px',
      height: 'fit-content',
      ...sizeStyles[size],
      ...(opacity !== undefined ? setBackgroundOpacity(colorStyles[color], opacity) : colorStyles[color]),
      ...(variant === 'text' && textVariant),
      ...(variant === 'textLink' && textLinkVariant),
      ...(fontSize ? fontSizeOverride : {}),
    };
  },
);

const MapleButton = forwardRef<HTMLButtonElement, MapleButtonProps>(({ size = 'medium', ...props }, ref) => {
  return <StyledButton disableRipple size={size} ref={ref} {...props} />;
});

export default MapleButton;
