import { USDC_DECIMALS } from 'Constants/tokens';
import { formatUnits, parseUnits } from 'viem/utils';
import { buildValueInterfaceFromParsedValues } from './valueInterface';

const getDecimals = (decimals: number, decimalNumber: string) => {
  if (decimals === 0) {
    return '';
  } else {
    return decimalNumber.substr(0, decimals);
  }
};

export const addTrailingZerosToDecimalNumber = (decimalNumber: string, decimalsToShow: number) => {
  if (decimalsToShow > 0) {
    const isNotDecimalNumber = !decimalNumber.includes('.');
    if (isNotDecimalNumber) decimalNumber += '.0';
    const splitDecimal = decimalNumber.split('.');
    let fractionalNumbers = splitDecimal[1];
    if (fractionalNumbers.length < decimalsToShow) {
      while (fractionalNumbers.length < decimalsToShow) fractionalNumbers += '0';
      const integralNumbers = splitDecimal[0];
      decimalNumber = [integralNumbers, fractionalNumbers].join('.');
    }
  }

  return decimalNumber;
};

export const formatNumber = (number: string, decimals: number) => {
  const trimmedNumber = number.trim().replaceAll(',', '');
  const [integerPart, decimalPart] = trimmedNumber.split('.');

  // Checks if the number ends with a decimal point but has no decimal digits after it.
  if (trimmedNumber.endsWith('.')) {
    const oldValue = trimmedNumber.slice(0, -1); // Remove the trailing decimal point.
    // If decimals parameter is zero, format only the integer part; otherwise, add a decimal point.
    return decimals === 0 ? i18nStringifiedNumberFormat(oldValue) : `${i18nStringifiedNumberFormat(oldValue)}.`;
  }

  // Checks if the input string is not a valid number after removing commas.
  if (isNaN(+trimmedNumber)) {
    const numberValue = trimmedNumber.replace(/[^\d.-]/g, ''); // Remove any non-digit, non-decimal, non-negative symbols.
    return i18nStringifiedNumberFormat(numberValue, decimals);
  }

  // Checks if there are decimal digits and if they exceed the specified number of decimal places (decimals).
  if (decimalPart?.length > 0 && decimalPart?.length > decimals) {
    const truncatedDecimal = decimalPart.substring(0, decimals);
    const newValue = `${integerPart}${decimals > 0 ? `.${truncatedDecimal}` : ''}`;
    return i18nStringifiedNumberFormat(newValue, decimals);
  }

  // Checks if there's no decimal part, or if it's zero ("00" or equivalent when parsed).
  if (!decimalPart || decimalPart === '00' || parseFloat('0.' + decimalPart) === 0) {
    return i18nStringifiedNumberFormat(integerPart); // Return the integer part only.
  }

  // Default case: format the full number with specified decimal places.
  return i18nStringifiedNumberFormat(trimmedNumber, decimals);
};

export const getFormattedNumber = ({
  bigNumber,
  decimals,
  decimalsToShow = 0,
}: {
  bigNumber: bigint;
  decimals: number;
  decimalsToShow?: number;
}) => {
  const formatUnitsString = formatUnits(bigNumber, decimals);
  const formatUnitsStringWithTrailingZeroes = addTrailingZerosToDecimalNumber(formatUnitsString, decimalsToShow);

  return formatNumber(formatUnitsStringWithTrailingZeroes, decimalsToShow);
};

export const i18nStringifiedNumberFormat = (value: string, decimals = 0, language = 'en'): string => {
  const [integerNumber, decimalNumber] = value.replaceAll(',', '').split('.');

  if (decimalNumber) {
    const decimalSeparator = Intl.NumberFormat(language)
      .formatToParts(1.1)
      .find(({ type }) => type === 'decimal');
    const theDecimals = getDecimals(decimals, decimalNumber);

    const formatted = Intl.NumberFormat(language).format(+integerNumber);

    if (decimals === 0 || +theDecimals === 0) {
      return `${formatted}`;
    } else {
      return `${formatted}${decimalSeparator ? decimalSeparator.value : '.'}${theDecimals}`;
    }
  }

  return Intl.NumberFormat(language, {
    maximumFractionDigits: decimals,
  }).format(+integerNumber);
};

export const isDust = (amount: bigint): boolean => {
  const traceUsdc = parseUnits('1', USDC_DECIMALS);

  const isDustLeftover = amount <= traceUsdc;

  return isDustLeftover;
};

export const isZeroish = (amount: bigint): boolean => {
  const traceUsdc = parseUnits('0.01', USDC_DECIMALS);

  const isDustLeftover = amount <= traceUsdc;

  return isDustLeftover;
};

export const i18nNumberAbreviation = (value: number, language = 'en', maximumFractionDigits = 1): string =>
  new Intl.NumberFormat(language, {
    maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);

export const i18nNumberAbreviationFromParsedValue = (value: string, decimals: number): string => {
  const valueInterface = buildValueInterfaceFromParsedValues(value, decimals);
  const formattedValueToNumber = +valueInterface.formatted.replaceAll(',', '');

  return i18nNumberAbreviation(formattedValueToNumber);
};
