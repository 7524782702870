import { PositionData, WithdrawalData } from 'Context/Portfolio/Portfolio';
import { Asset, PoolFragment, PoolWithdrawalFragment } from 'Graphql/schema';

import { ValueInterface, ZERO_USDC } from 'Utils/valueInterface';
import { ZERO } from 'Constants/numbers';

export const defaultValueInterface: ValueInterface = {
  formatted: '0',
  bigNumber: ZERO,
  parsed: '0',
};

export const defaultAssetData: Asset = {
  id: '',
  symbol: 'USDC', // todo how to handle default better - set on load? Problem because of network requests to get asset data
  decimals: 1,
  amount: undefined,
  amountAsLiquidity: undefined,
  chainLinkAggregator: undefined,
  isValidCollateralAsset: false,
  isValidLiquidityAsset: false,
  isValidPoolAsset: false,
  isValidStakeAsset: false,
  lastAggregatorCheckTimestamp: undefined,
  lastPriceUpdateTimestamp: undefined,
  price: undefined,
  priceFeed: undefined,
};

export const defaultPoolWithdrawalData: PoolWithdrawalFragment = {
  id: '',
  name: '',
  accountedInterest: undefined,
  assets: undefined,
  domainEnd: undefined,
  domainStart: undefined,
  issuanceRate: undefined,
  principalOut: undefined,
  shares: undefined,
  unrealizedLosses: undefined,
  asset: {
    __typename: undefined,
    decimals: 0,
    id: '',
    symbol: '',
  },
};

export const defaultPoolData: PoolFragment = {
  id: '',
  permissionLevel: 0,
  apyData: {
    apy: '0',
  },
  asset: {
    decimals: 0,
    id: '',
    symbol: '',
  },
};

export const defaultWithdrawalData: WithdrawalData = {
  withdrawalState: 'Inactive',
  requestedAmount: ZERO_USDC,
  requestedWithdrawalTx: undefined,
  remainingAmount: ZERO_USDC,
};

export const defaultPositionData: PositionData = {
  totalBalance: { ...ZERO_USDC },
  availableBalance: { ...ZERO_USDC },
  availableShares: { ...ZERO_USDC },
  lockedShares: { ...ZERO_USDC },

  interestEarned: { ...ZERO_USDC },

  commitments: [],
  firstCommitmentExpiry: null,
  dripsEarned: '0',
};
