import { Addresses } from 'Contracts/addresses';

const addresses: Addresses = {
  mpl: '0x10e5eeca17bce71ce113be10543f377e426f8873',
  xMPL: '0xeb5030ed8ccb819bdf6c1eb2e2c4d9e86e7721fc',
  syrupToken: '0x8313bcbe56109adb7ce6ae0f7922f68c4a687474',
  stSyrup: '0xe7ef059cf7b92699d2f4360da4937f7c0fc27110',
  syrupTokenMigrator: '0xdbbe7052e665eccb18d62eb4488914fe76d73dfc',
  mplUserActions: '0x76352b42cf762583537cc01ec98ca351bccd7f3b',
  pendleLP: '0x0000000000000000000000000000000000000000',
  pendlePT: '0x0000000000000000000000000000000000000000',
  pendleYT: '0x0000000000000000000000000000000000000000',
};

export default addresses;
